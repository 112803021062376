import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FavoritePassToggleModule } from '@FeatureUi/FavoritePassToggle/FavoritePassToggle.module';
import { NewTablesModule } from '@predictiveindex/client-core';
import { InterviewGuideReminderModalComponent } from './InterviewGuideReminderModal.component';

@NgModule({
    imports: [CommonModule, NewTablesModule, FavoritePassToggleModule],
    declarations: [InterviewGuideReminderModalComponent],
    exports: [InterviewGuideReminderModalComponent]
})
export class InterviewGuideRemindersModule {}
