import { Injectable } from '@angular/core';
import { AppLoadHelperService } from './app-load-helper.service';

@Injectable({ providedIn: 'root' })
export class LegacyModuleGuard {
    appStatus: boolean;

    constructor(private appLoadHelper: AppLoadHelperService) {}
    /**
     * The following guard/method is used to check if the LegacyModule call check has loaded completely before allowing other modules to load.
     * The Angular routes were loaded before the call is ocmpleted and that prematurely kills the legacyModule call and subsequently skips navigating to Platform app for 2.0 accounts.
     * This guard ensures that doesn't happen.
     */
    async canLoad() {
        return await this.resolveTimeout();
    }

    async canActivate() {
        return await this.resolveTimeout();
    }

    async canActivateChild() {
        return await this.resolveTimeout();
    }

    resolveTimeout(): Promise<boolean> {
        return this.appStatus
            ? Promise.resolve(true)
            : new Promise((resolve) => {
                  const interval = setInterval(() => {
                      this.appStatus = this.appLoadHelper.isComplete;
                      if (this.appStatus) {
                          clearInterval(interval);
                          resolve(true);
                          return;
                      }
                  }, 200);
              });
    }
}
