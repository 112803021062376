import { Component, Input } from '@angular/core';
import { SpaDataCollectionService } from '@DataCollection/spa-data-collection.service';
import {
    Impression,
    PersonApi,
    TranslationService
} from '@predictiveindex/client-core';

@Component({
    selector: 'pi-favorite-pass-toggle',
    template: `
        <div class="pi-favorite-pass-toggle">
            <button
                (click)="onClick($event, Impression.Favorite)"
                [disabled]="this.disabled"
                tooltip
                [tooltipContent]="TR.key('label.common-terms.favorite')"
                [tooltipDelay]="1000"
                tooltipPlacement="top"
            >
                <i
                    [ngClass]="{
                        selected: favorite === Impression.Favorite
                    }"
                    class="fa fa-heart"
                >
                </i>
            </button>
            <button
                (click)="onClick($event, Impression.Rejected)"
                [disabled]="this.disabled"
                tooltip
                [tooltipContent]="TR.key('label.common-terms.pass')"
                [tooltipDelay]="1000"
                tooltipPlacement="top"
            >
                <i
                    [ngClass]="{
                        selected: favorite === Impression.Rejected
                    }"
                    class="fa fa-ban"
                >
                </i>
            </button>
        </div>
    `
})
export class FavoritePassToggleComponent {
    Impression = Impression;

    @Input()
    favorite: Impression;
    @Input()
    personId: string;
    @Input()
    disabled: boolean;

    constructor(
        public TR: TranslationService,
        private personApi: PersonApi,
        private spaDataCollectionService: SpaDataCollectionService
    ) {
        this.disabled = false;
    }

    onClick(event: MouseEvent, value: Impression): void {
        this.spaDataCollectionService.stopPropagationAndCollectData(event);
        // Selecting the same one removes it
        if (this.favorite === value) {
            this.favorite = Impression.Unset;
        } else {
            this.favorite = value;
        }
        this.personApi.updatePerson(this.personId, {
            impression: this.favorite
        });
    }
}
