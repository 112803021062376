import { SpaDataCollectionService } from '@DataCollection/spa-data-collection.service';
import { InterviewGuideRemindersModule } from '@FeatureUi/InterviewGuideReminders/InterviewGuideReminders.module';
import { SafeHtmlPipe } from '@ServicesAndTools/Pipes/SanitizeHtml.pipe';
import { SafeUrlPipe } from '@ServicesAndTools/Pipes/UrlTransformations.pipe';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    Injector,
    NgModule
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import {
    AdblockDetectorService,
    AppBootstrapHelpersService,
    AppType,
    AuthenticationService,
    CURRENT_APP_TYPE,
    CoreUiModule,
    FeatureFlagsService,
    GlobalModalModule,
    HashService,
    InitData,
    LoadingPageModule,
    LocalStorageService,
    LogRocketService,
    ModalsModule,
    Module,
    PIUniversalNavModule,
    PeekModule,
    PlatformBootstrapHelperService,
    TranslationsModule,
    appInjector
} from '@predictiveindex/client-core';
import { TapestrySidenavModule } from '@predictiveindex/tapestry';
import { ScrollToModule } from '@urajendran-pi/ngx-scroll-to';
import { ToastrModule } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ShareLinkLoadingPage } from './ShareLinkLoading.page';
import { UpgradeFailureBannerComponent } from './UpgradeFailureBanner/upgrade-failure-banner.component';
import { AppLoadHelperService } from './app-load-helper.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        ScrollToModule.forRoot(),
        ToastrModule.forRoot(),
        GlobalModalModule,
        InterviewGuideRemindersModule,
        CoreUiModule,
        AppRoutingModule,
        PeekModule,
        LoadingPageModule,
        ModalsModule,
        PIUniversalNavModule,
        TapestrySidenavModule,
        TranslationsModule
    ],
    declarations: [
        AppComponent,
        SafeUrlPipe,
        SafeHtmlPipe,
        // Only place this is used for now. Pull out into a module if it becomes re-used
        ShareLinkLoadingPage,
        UpgradeFailureBannerComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        { provide: CURRENT_APP_TYPE, useValue: AppType.Main },
        {
            provide: APP_INITIALIZER,
            useFactory: appInit,
            deps: [
                AppBootstrapHelpersService,
                AuthenticationService,
                AdblockDetectorService,
                SpaDataCollectionService,
                LogRocketService,
                PlatformBootstrapHelperService,
                HashService,
                LocalStorageService,
                AppLoadHelperService
            ],
            multi: true
        },
        { provide: Window, useValue: window },
        { provide: Document, useValue: document },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        DatePipe,
        SafeUrlPipe,
        SafeHtmlPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(injector: Injector) {
        appInjector(injector);
    }
}

async function bootstrapApp(
    appBootstrapHelpers: AppBootstrapHelpersService,
    adblockDetector: AdblockDetectorService,
    spaDataCollectionService: SpaDataCollectionService,
    logRocketService: LogRocketService
) {
    // Initialize DataDog ASAP so we can capture all calls
    appBootstrapHelpers.datadogInit(
        '5839e56b-e799-416a-b5b3-d563875701de',
        'pubec5261a15485a5c79612d5bdab1457ae'
    );

    let initData: InitData;

    let loadSprites = appBootstrapHelpers.loadSpriteSheetsAndTranslations();
    let loadSoftwareModuleAccess = (async () => {
        initData = await appBootstrapHelpers.bootstrapInitDataAndModuleAccess();
    })();

    await Promise.all([loadSprites, loadSoftwareModuleAccess]);

    const featureFlags: FeatureFlagsService =
        appInjector().get(FeatureFlagsService);

    await featureFlags.completionPromise;

    logRocketService.initialize(Module.Spa);
    spaDataCollectionService.initializeDataCollection(initData);

    adblockDetector.checkAndLogIfServiceBlocked();
}

// Do not load the angular app if the initData promise fails to resolve
function appInit(
    appBootstrapHelpers: AppBootstrapHelpersService,
    auth: AuthenticationService,
    adblockDetector: AdblockDetectorService,
    spaDataCollectionService: SpaDataCollectionService,
    logRocketService: LogRocketService,
    platformHelperService: PlatformBootstrapHelperService,
    hashService: HashService,
    localStorage: LocalStorageService,
    appLoadHelper: AppLoadHelperService
) {
    return async () => {
        appLoadHelper.isComplete = false;
        const isAuthenticated: boolean = await firstValueFrom(
            auth.isAuthenticated()
        );

        if (isAuthenticated) {
            const redirecting = await auth.redirectToTargetPath();

            if (redirecting) {
                return;
            }

            // Get current User's email
            const userEmail = (await firstValueFrom(auth.getUser())).email;

            // Fetch the last logged in Company Id
            const hashedEmail = await hashService.computeHashHex(userEmail);
            const companyId: string = localStorage.getItem(
                `${hashedEmail}-currentCompany`
            );
            const deepLinkState = await auth.fetchDeepLink();

            // Check to make sure that we're not allowing 1.0 users to access Platform app.
            if (companyId) {
                await platformHelperService.checkLegacyModuleAccessAndNavigate(
                    await firstValueFrom(auth.getToken()),
                    companyId,
                    deepLinkState
                );
            } else {
                await platformHelperService.checkLegacyModuleAccessAndNavigate(
                    await firstValueFrom(auth.getToken()),
                    null,
                    deepLinkState
                );
            }

            appLoadHelper.isComplete = true;

            return await bootstrapApp(
                appBootstrapHelpers,
                adblockDetector,
                spaDataCollectionService,
                logRocketService
            );
        }

        return await auth.goToLogin();
    };
}
