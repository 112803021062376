import { Component } from '@angular/core';
import { TranslationsModule } from '@predictiveindex/client-core';

@Component({
    selector: 'pi2-upgrade-page',
    standalone: true,
    imports: [TranslationsModule],
    template: `<div class="piApp--Content--globalnav pageWidthLimiter">
        <div class="page-container">
            <div class="image-container">
                <img [attr.src]="imageSource" />
            </div>

            <div class="tapestry-title title--lg title-spacing">
                {{ 'pi2-upgrade-in-progress.page.title' | translate }}
            </div>

            <div class="content-container">
                <div class="subheader tapestry-body body--xl bold">
                    {{ 'pi2-upgrade-in-progress.page.subheader-1' | translate }}
                </div>
                <div
                    class="tapestry-body body--xl"
                    [innerHTML]="
                        'pi2-upgrade-in-progress.page.content-1' | translate
                    "
                ></div>
                <div class="subheader tapestry-body body--xl bold">
                    {{ 'pi2-upgrade-in-progress.page.subheader-2' | translate }}
                </div>
                <div
                    class="tapestry-body body--xl"
                    [innerHTML]="
                        'pi2-upgrade-in-progress.page.content-2' | translate
                    "
                ></div>
            </div>
        </div>
    </div> `,
    styleUrls: ['./upgrade-in-progress.page.less']
})
export class UpgradeInProgressPageComponent {
    imageSource = '/Assets/PI2Upgrade/bullhorn_purple.svg';

    constructor() {}
}
