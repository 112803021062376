import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    errorPageQueryString,
    PageErrorType,
    TranslationService
} from '@predictiveindex/client-core';
import { LoadingService } from './Loading.service';

@Component({
    template: `
        <div>
            <loading-dots></loading-dots>
        </div>
    `,
    styles: []
})
export class ShareLinkLoadingPage implements OnInit {
    @Input()
    companyId: string;

    @Input()
    linkId: string;

    constructor(
        public loadingService: LoadingService,
        public TR: TranslationService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.linkId = this.route.snapshot.paramMap.get('id');
        this.createPermissionsForLink();
    }

    async createPermissionsForLink() {
        try {
            let redirectUrl = await this.loadingService.createPermissions(
                this.companyId,
                this.linkId
            );
            this.onSuccess(redirectUrl);
        } catch (e) {
            console.error(e);
            this.onError();
        }
    }

    onSuccess(redirectUrl: string) {
        this.router.navigate([`/${redirectUrl}`]);
    }

    onError() {
        const error: PageErrorType = 'unknown';
        this.router.navigate([`/error?${errorPageQueryString}=${error}`]);
    }
}
