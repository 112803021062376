import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    FeatureFlags,
    FeatureFlagsService,
    ModuleUserAccessService
} from '@predictiveindex/client-core';
import { PermissionsModuleAccessLevel } from '@predictiveindex/client-permissions-lib';

@Injectable({ providedIn: 'root' })
export class HasBillingManager  {
    billingManagerEnabled: boolean = false;

    constructor(
        private router: Router,
        private featureFlags: FeatureFlagsService,
        private moduleUserAccess: ModuleUserAccessService
    ) {
        this.billingManagerEnabled = this.featureFlags.getByFlag(
            FeatureFlags.allowAccessToBillingManagerRoutes
        );
    }

    canActivate() {
        if (
            this.billingManagerEnabled &&
            this.moduleUserAccess.hasAccessLevelForAnyModule(
                PermissionsModuleAccessLevel.AllCompanyAdmin
            )
        ) {
            return true;
        }
        this.router.navigate(['dashboard']);
        return false;
    }
}
