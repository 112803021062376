import { Injectable } from '@angular/core';
import { ShareLinkLoadingApi } from './ShareLinkLoadingApi.connect';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    constructor(private shareLinkLoadingApi: ShareLinkLoadingApi) {}

    async createPermissions(
        companyId: string,
        linkId: string
    ): Promise<string> {
        return this.shareLinkLoadingApi.createPermissions(companyId, linkId);
    }
}
