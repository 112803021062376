import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class InspireProxyGuard  {
    constructor(private router: Router) {}

    async canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        let port = null;
        // Check if the app is in production or development build
        if (window.location.hostname.includes('localhost')) {
            // In development use the local inspire port instead of the standard 443/80
            port = 4208;
        }
        /*
            This updates the URl depending on if we are in development or not.  In development we use the local inspire server at
            localhost:4208.  In production mode we use the normal URL such as node-dev.predictiveindex.com.

            The reason for this change is because SPA is not going to be responsible for proxying to Inspire.  Instead in a deployed
            environment azure front door (AFD) will handle the path routing.
        */
        window.location.assign(
            `${window.location.protocol}//${
                // locally use the hostname otherwise preserve the hostname + port
                port ? window.location.hostname : window.location.host
                // locally use the provided port otherwise do not change the port
            }${port ? ':' + port : ''}${state.url}`
        );
        return true;
    }
}
