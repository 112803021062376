import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
    InitDataService,
    ModuleAccessService
} from '@predictiveindex/client-core';

/**
 * This route guards purpose is to ensure that users who have bookmarked certain tabs in the
 * Admin menu are still able to reach those tabs when they are given access to the new Admin
 * feature
 */
@Injectable({ providedIn: 'root' })
export class AdministrationFeatureRouteGuard  {
    companySettingsPaths = [
        'anonymize',
        'emails',
        'general',
        'dataprivacy',
        'fielddisplay'
    ];
    userSettingsPaths = ['users', 'thirdpartyusers'];

    constructor(private router: Router) {}

    canActivateChild(route: ActivatedRouteSnapshot) {
        if (
            this.companySettingsPaths.includes(
                route.routeConfig.path.toLowerCase()
            )
        ) {
            this.router.navigate(['administration/company']);
            return false;
        } else if (
            this.userSettingsPaths.includes(
                route.routeConfig.path.toLowerCase()
            )
        ) {
            this.router.navigate(['administration/usersmanagement']);
            return false;
        }
        return true;
    }
}

@Injectable({ providedIn: 'root' })
export class HasOnlyDesignGuard  {
    fullExperienceOnlyAdminPaths = ['folders', 'company'];

    constructor(
        private router: Router,
        private moduleAccess: ModuleAccessService
    ) {}

    get isDesignStandalone() {
        return this.moduleAccess.isDesignStandalone;
    }

    canActivate(route: ActivatedRouteSnapshot) {
        if (
            this.fullExperienceOnlyAdminPaths.includes(
                route.routeConfig.path.toLowerCase()
            ) &&
            this.isDesignStandalone
        ) {
            this.router.navigate(['administration/usersmanagement']);
            return false;
        }
        if (
            route.routeConfig.path.toLowerCase() === 'dashboard' &&
            this.isDesignStandalone
        ) {
            this.router.navigate(['design']);
            return false;
        }
        return true;
    }
}

@Injectable({ providedIn: 'root' })
export class IsAbacUserGuard  {
    constructor(private router: Router, private initData: InitDataService) {}
    canActivateChild() {
        if (this.initData.isAbacUser()) {
            this.router.navigate(['/abacDashboard']);
            return false;
        }
    }
    canActivate() {
        if (!this.initData.javaScriptInitData) {
            return false;
        }

        if (this.initData.isAbacUser()) {
            this.router.navigate(['abacDashboard']);
            return false;
        }
        return true;
    }
}

@Injectable({ providedIn: 'root' })
export class IsNotDiagnose2Guard  {
    constructor(
        private moduleAccess: ModuleAccessService,
        private router: Router
    ) {}
    canActivate() {
        if (this.isDiagnose2()) {
            this.router.navigate(['diagnose']);
            return false;
        }
        return true;
    }

    /**
     * Returns true (as user should be redirected to Survey Center)
     * if the user has Diagnose standalone and they are marked
     * for the Diagnose 2.0 experience.
     *
     * This logic is duplicated in client-core in the `user-dropdown` component.
     */
    isDiagnose2() {
        return (
            this.moduleAccess.isDiagnoseStandalone &&
            this.moduleAccess.isDiagnoseSelfServe
        );
    }
}
