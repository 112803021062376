import { Component, Input } from '@angular/core';
import {
    BasicContextView,
    IDynamicallyCreatedComponent,
    Impression,
    InterviewGuideReminder,
    ModalsService,
    Person,
    SearchStateFactory,
    TableManagerDataService,
    TranslationService,
    Util
} from '@predictiveindex/client-core';

@Component({
    template: `
        <section>
            <p class="text-bold">
                {{ TR.key('interviewGuideReminder.title') }}
            </p>

            <p>{{ TR.key('interviewGuideReminder.bodyText') }}</p>
        </section>

        <section>
            <pi-table class="pi-searchGrid" [table]="tableData">
                <ng-template #header>
                    <th
                        class="pi-table_cell--iconSize pi-table_cell--centered"
                    ></th>
                    <th
                        class="pi-table_cell--textLeftAligned pi-table_cell--largeSize"
                    >
                        {{ TR.key('label.common-terms.name') }}
                    </th>
                    <th class="pi-table_cell--textLeftAligned">
                        {{ TR.key('label.common-terms.job') }}
                    </th>
                    <th>
                        {{ TR.key('label.common-terms.favorite') }}
                    </th>
                </ng-template>
                <ng-template #dataRow let-item="item">
                    <td class="pi-table_cell--iconSize pi-table_cell--centered">
                        <table-icon [result]="item"></table-icon>
                    </td>
                    <td
                        class="pi-table_cell--textLeftAligned pi-table_cell--largeSize"
                    >
                        <result-link [result]="item"></result-link>
                    </td>
                    <td class="pi-table_cell--textLeftAligned">
                        <job-link [result]="item"></job-link>
                    </td>
                    <td>
                        <pi-favorite-pass-toggle
                            [favorite]="item.jobFit?.personImpression"
                            [personId]="item.id"
                        >
                        </pi-favorite-pass-toggle>
                    </td>
                </ng-template>
            </pi-table>
        </section>

        <div class="btn--lsg_centeredContainer">
            <button
                (click)="modalsService.closeCurrentModal(false)"
                class="btn btn--lsg"
            >
                {{ TR.key('text.common-terms.done') }}
            </button>
        </div>
    `
})
export class InterviewGuideReminderModalComponent
    implements IDynamicallyCreatedComponent {
    Impression = Impression;

    @Input()
    person: Person;
    @Input()
    reminders: InterviewGuideReminder[];

    tableData: BasicContextView;

    get propertiesList(): string[] {
        return ['person', 'reminders'];
    }

    constructor(
        public TR: TranslationService,
        public modalsService: ModalsService,
        private util: Util,
        tableManager: TableManagerDataService,
        searchFactory: SearchStateFactory
    ) {
        this.tableData = tableManager.makeInterviewGuideReminderTable();
        this.tableData.searchState = searchFactory.newSearchState();
    }

    generateQuestionText() {
        return this.person
            ? this.util.strings.formatString(
                  this.TR.key('candidateFeedback.question'),
                  this.person.firstName,
                  this.person.lastName
              )
            : '';
    }
}
