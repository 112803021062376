import {
    HasOnlyDesignGuard,
    IsAbacUserGuard,
    IsNotDiagnose2Guard
} from '@Administration/AdministrationFeatureRouteGuard.guard';
import { ModuleSSRedirectorGuard } from '@SharedHire/HireVersion.guard';
import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
    mapToCanActivate,
    mapToCanActivateChild
} from '@angular/router';
import {
    AuthenticationGuard,
    Inspire2Link,
    InternalActivationsPageComponent,
    LoadingPage,
    NonSelfServeModulesOnly,
    adminRoutePath,
    diagnose2RoutePath,
    hire2RoutePath
} from '@predictiveindex/client-core';
import { ShareLinkLoadingPage } from './ShareLinkLoading.page';
import { UpgradeInProgressPageComponent } from './UpgradeInProgressPage';
import { PI2UpgradeGuard } from './UpgradeInProgressPage/pi2-upgrade.guard';
import { HasBillingManager } from './account-billing-manager.guard';
import { HasSubscriptionManager } from './account-subscription-manager.guard';
import { AdminProxyGuard } from './admin-proxy.guard';
import { DiagnoseProxyGuard } from './diagnose-proxy.guard';
import { ExplainerPageGuard } from './explainer-page-guard';
import { ExternalRedirectGuard } from './external-redirect.guard';
import { HireProxyGuard } from './hire-proxy-guard';
import { InspireProxyGuard } from './inspire-proxy.guard';
import { LegacyModuleGuard } from './legacy-module.guard';

// TODO Consider packaging components here so they don't need to be relisted in app.ts
const appRoutes: Routes = [
    {
        path: '',
        canActivate: mapToCanActivate([
            AuthenticationGuard,
            LegacyModuleGuard,
            PI2UpgradeGuard
        ]),
        canActivateChild: mapToCanActivateChild([
            AuthenticationGuard,
            LegacyModuleGuard,
            PI2UpgradeGuard
        ]),
        children: [
            {
                path: 'dashboard',
                canActivate: mapToCanActivate([
                    ModuleSSRedirectorGuard,
                    HasOnlyDesignGuard,
                    IsAbacUserGuard,
                    IsNotDiagnose2Guard
                ]),
                loadChildren: () =>
                    import('@Dashboard/DashboardRoutetown.module').then(
                        (m) => m.DashboardRoutetownModule
                    )
            },
            {
                path: 'abacDashboard',
                loadChildren: () =>
                    import('@Dashboard/AbacDashboardRoutetown.module').then(
                        (m) => m.AbacDashboardRoutetownModule
                    )
            },
            {
                path: 'organization',
                canActivate: mapToCanActivate([
                    IsAbacUserGuard,
                    NonSelfServeModulesOnly
                ]),
                loadChildren: () =>
                    import('@OrgUpload/OrgUploadRoutetown.module').then(
                        (m) => m.OrgUploadRoutetownModule
                    )
            },

            // Uncomment this and hit this url to generate and download updated cog SVGs
            // { path: 'generateCognitiveSVGs', component: GenerateCognitiveSVGsComponent },
            // Handle search on empty string ("") on refresh which strips trailing forward slash
            {
                path: 'searchResults',
                redirectTo: 'searchResults/',
                pathMatch: 'full'
            },
            {
                path: 'searchResults',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@SearchResultsTable/SearchRoutetown.module').then(
                        (m) => m.SearchRoutetownModule
                    )
            },
            {
                path: 'person',
                loadChildren: () =>
                    import('@Person/PersonRoutetown.module').then(
                        (m) => m.PersonRoutetownModule
                    ),
                canActivate: mapToCanActivate([IsAbacUserGuard])
            },
            // freemium - self serve(?)
            // module experience type
            {
                path: 'jobs',
                redirectTo: 'job/directory'
            },

            {
                path: 'job',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@Job/JobRoutetown.module').then(
                        (m) => m.JobRoutetownModule
                    )
            },

            { path: 'modifyJob/:id', redirectTo: '/job/modify/:id' },

            {
                path: 'assessmentCenter',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@AssessmentCenter/AssessmentCenterRoutetown.module'
                    ).then((m) => m.AssessmentCenterRoutetownModule)
            },
            {
                path: 'manageAssessmentLinks',
                redirectTo: 'assessmentCenter/manageAssessmentLinks'
            },
            {
                path: 'recentlyCompleted',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@RecentlyCompleted/RecentlyCompletedRoutetown.module'
                    ).then((m) => m.RecentlyCompletedRoutetownModule)
            },
            {
                path: 'pendingAssessments',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@PendingAssessments/PendingAssessmentsRoutetown.module'
                    ).then((m) => m.PendingAssessmentsRoutetownModule)
            },

            {
                path: 'createJob',
                canActivate: mapToCanActivate([
                    IsAbacUserGuard,
                    ModuleSSRedirectorGuard
                ]),
                loadChildren: () =>
                    import('./Routes/CreateOrSelectJobRoutetown.module').then(
                        (m) => m.CreateOrSelectJobRoutetownModule
                    )
            },

            {
                path: 'setJobTarget',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@SetJobTarget/SetJobTargetRoutetown.module').then(
                        (m) => m.SetJobTargetRoutetownModule
                    )
            },

            {
                path: 'assessCandidates',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@AssessCandidates/AssessPeopleRoutetown.module'
                    ).then((m) => m.AssessPeopleRoutetownModule)
            },

            {
                path: 'onboardNewEmployees',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@OnboardNewEmployees/OnboardNewEmployeesRoutetown.module'
                    ).then((m) => m.OnboardNewEmployeesRoutetownModule)
            },
            {
                path: 'interviewCandidates',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@InterviewCandidates/InterviewCandidatesRoutetown.module'
                    ).then((m) => m.InterviewCandidatesRoutetownModule)
            },

            {
                path: 'employeeDirectory',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@EmployeeDirectory/EmployeeDirectoryRoutetown.module'
                    ).then((m) => m.EmployeeDirectoryRoutetownModule)
            },

            // TODO: Delete these redirects in 3 months from today (11/12/18)
            { path: 'individualsLanding', redirectTo: 'inspire/individuals' },
            { path: 'managersLanding', redirectTo: 'inspire/managers' },
            { path: 'teamsLanding', redirectTo: 'inspire/teams' },
            {
                path: 'coachingguide',
                redirectTo: 'inspire/coachingguide'
            },
            {
                path: 'relationship',
                redirectTo: 'inspire/relationship'
            },
            {
                path: 'managerDevelopmentChart',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@ManagerDevelopmentChart/ManagerDevelopmentChartRoutetown.module'
                    ).then((m) => m.ManagerDevelopmentRoutetownModule)
            },
            {
                path: 'personalDevelopmentChart',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@PersonalDevelopmentChart/PersonalDevelopmentChartRoutetown.module'
                    ).then((m) => m.PersonalDevelopmentChartRoutetownModule)
            },

            {
                path: 'placard',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@PlacardReport/PlacardReportPageRoutetown.module'
                    ).then((m) => m.PlacardReportPageRoutetownModule)
            },
            {
                path: 'managementStrategyGuide',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@ManagerStrategyGuide/ManagerStrategyGuideRoutetown.module'
                    ).then((m) => m.ManagerStrategyGuideRoutetownModule)
            },
            {
                path: 'inspire',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@Inspire/InspireRoutetown.module').then(
                        (m) => m.InspireRoutetownModule
                    )
            },
            {
                path: 'folders',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@Folders/FoldersRoutetown.module').then(
                        (m) => m.FoldersRoutetownModule
                    )
            },

            {
                path: 'groups',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@Teams/GroupsRoutetown.module').then(
                        (m) => m.GroupsRoutetownModule
                    )
            },
            {
                path: 'analyzeGroups',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@TeamAnalytics/GroupAnalyticsRoutetown.module'
                    ).then((m) => m.GroupAnalyticsRoutetownModule)
            },

            {
                path: 'administration',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@Administration/AdministrationRoutetown.module'
                    ).then((m) => m.AdministrationRoutetownModule)
            },
            {
                path: 'subscription',
                canActivate: mapToCanActivate([HasSubscriptionManager]),
                loadChildren: () =>
                    import('@predictiveindex/client-core').then(
                        (m) => m.AccountSubscriptionManagerRoutetownModule
                    )
            },
            {
                path: 'billing',
                canActivate: mapToCanActivate([HasBillingManager]),
                loadChildren: () =>
                    import('@predictiveindex/client-core').then(
                        (m) => m.AccountBillingManagerRoutetownModule
                    )
            },
            {
                path: 'contact',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        'libs/general/contact-support/src/lib/ContactSupportRoutetown.module'
                    ).then((m) => m.ContactSupportRoutetownModule)
            },

            {
                path: 'myprofile',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@MyProfile/MyProfileRoutetown.module').then(
                        (m) => m.MyProfileRoutetownModule
                    )
            },
            {
                path: 'engage',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@Engage/EngageRoutetown.module').then(
                        (m) => m.EngageRoutetownModule
                    )
            },
            {
                path: 'styleguide',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@StyleGuide/StyleGuideRoutetown.module').then(
                        (m) => m.StyleGuideRoutetownModule
                    )
            },
            {
                path: 'tapestryPlayground',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import(
                        '@TapestryPlayground/tapestry-plagyround-routetown.module'
                    ).then((m) => m.TapesteryPlaygroundRoutetownModule)
            },
            {
                path: 'onboarding',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@Onboarding/OnboardingRoutetown.module').then(
                        (m) => m.OnboardingRoutetownModule
                    )
            },
            {
                path: '404',
                canActivate: mapToCanActivate([IsAbacUserGuard]),
                loadChildren: () =>
                    import('@RouteNotFound/RouteNotFoundRoutetown.module').then(
                        (m) => m.RouteNotFoundRoutetownModule
                    )
            },
            // Catch routes for the Hire proxy app and send them along to that
            // app via a full page redirect
            {
                path: `${hire2RoutePath}`,
                component: LoadingPage,
                canActivate: mapToCanActivate([HireProxyGuard])
            },
            {
                path: `${hire2RoutePath}/:anyroute`,
                component: LoadingPage,
                canActivate: mapToCanActivate([HireProxyGuard])
            },
            {
                path: `${hire2RoutePath}/:anyroute/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([HireProxyGuard])
            },
            {
                path: `${hire2RoutePath}/:anyroute/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([HireProxyGuard])
            },
            {
                path: `${hire2RoutePath}/:anyroute/:anything/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([HireProxyGuard])
            },
            // Catch routes for the Design app and send them along to that
            // app via a full page redirect
            {
                path: 'design',
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: 'design/:anyroute',
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: 'design/:anyroute/:anything',
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: 'design/:anyroute/:anything/:anything',
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: 'design/:anyroute/:anything/:anything/:anything',
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            // Catch routes for the Diagnose app and send them along to that
            // app via a full page redirect
            {
                path: `${diagnose2RoutePath}`,
                component: LoadingPage,
                canActivate: mapToCanActivate([DiagnoseProxyGuard])
            },
            {
                path: `${diagnose2RoutePath}/:anyroute`,
                component: LoadingPage,
                canActivate: mapToCanActivate([DiagnoseProxyGuard])
            },
            {
                path: `${diagnose2RoutePath}/:anyroute/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: `${diagnose2RoutePath}/:anyroute/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: `${diagnose2RoutePath}/:anyroute/:anything/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            // Catch routes for Inspire 2.0 and send to the Inspire 2.0 app
            // via full redirect
            {
                path: `${Inspire2Link}`,
                component: LoadingPage,
                canActivate: mapToCanActivate([InspireProxyGuard])
            },
            {
                path: `${Inspire2Link}/:anyroute`,
                component: LoadingPage,
                canActivate: mapToCanActivate([InspireProxyGuard])
            },
            {
                path: `${Inspire2Link}/:anyroute/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: `${Inspire2Link}/:anyroute/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: `${Inspire2Link}/:anyroute/:anything/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            // Catch routes for the Admin app and send them along to that
            // app via a full page redirect
            {
                path: `${adminRoutePath}`,
                component: LoadingPage,
                canActivate: mapToCanActivate([AdminProxyGuard])
            },
            {
                path: `${adminRoutePath}/:anyroute`,
                component: LoadingPage,
                canActivate: mapToCanActivate([AdminProxyGuard])
            },
            {
                path: `${adminRoutePath}/:anyroute/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: `${adminRoutePath}/:anyroute/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            {
                path: `${adminRoutePath}/:anyroute/:anything/:anything/:anything`,
                component: LoadingPage,
                canActivate: mapToCanActivate([ExternalRedirectGuard])
            },
            // Redirect page from share link
            {
                path: 'shareLinkLoading/:id',
                component: ShareLinkLoadingPage
            },
            {
                path: 'unlock/:module',
                component: InternalActivationsPageComponent,
                canActivate: mapToCanActivate([
                    IsAbacUserGuard,
                    ExplainerPageGuard
                ])
            },
            // 2023-01-12: Since 'login' was a valid route for so long, redirect for now
            {
                path: 'login',
                redirectTo: '/dashboard'
            },
            // 2023-01-12: No idea when 'authenticator' was valid, redirect until we also remove 'login'
            {
                path: 'authenticator',
                redirectTo: '/dashboard'
            },
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full'
            },
            {
                path: 'upgrade-in-progress',
                component: UpgradeInProgressPageComponent,
                pathMatch: 'full'
            }
        ]
    },
    {
        path: '**',
        loadChildren: () =>
            import('@RouteNotFound/RouteNotFoundRoutetown.module').then(
                (m) => m.RouteNotFoundRoutetownModule
            )
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
