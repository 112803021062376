import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {
    DevelopmentPorts,
    Hire2Link,
    ModuleAccessService,
    UserProfileSettingsService
} from '@predictiveindex/client-core';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class HireProxyGuard  {
    constructor(
        private router: Router,
        private userProfileSettingService: UserProfileSettingsService,
        private moduleAccess: ModuleAccessService
    ) {}
    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (
            this.moduleAccess.hasHireHero &&
            this.moduleAccess.isHireHeroSelfServe
        ) {
            // Code to support backwards compatibilty for the route /hire/{jobId}
            const routePath = state.url.split(Hire2Link + '/')[1]; // extract the sub path
            const guidPattern =
                /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

            if (guidPattern.test(routePath)) {
                //Redirect to hire/jobs/{id}
                state.url = Hire2Link + `/jobs/${routePath}`;
            }
            let port = null;
            // Check if the app is in production or development build
            if (window.location.hostname.includes('localhost')) {
                // In development use the local hire port instead of the standard 443/80
                port = DevelopmentPorts.hire;
            }
            /*
                This updates the URl depending on if we are in development or not.  In development we use the local hire server at
                localhost:4206.  In production mode we use the normal URL such as node-dev.predictiveindex.com.

                The reason for this change is because SPA is no longer going to be responsible for proxying to Hire.  Instead in a deployed
                environment azure front door (AFD) will handle the path routing.
            */
            window.location.assign(
                `${window.location.protocol}//${
                    // locally use the hostname otherwise preserve the hostname + port
                    port ? window.location.hostname : window.location.host
                    // locally use the provided port otherwise do not change the port
                }${port ? ':' + port : ''}${state.url}`
            );
        } else {
            this.lookupHireClassicRoute();
        }
        return false;
    }

    lookupHireClassicRoute() {
        return this.router.navigate(['/job/directory']);
    }
}
