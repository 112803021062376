import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
    ContextDataService,
    Impression,
    InterviewGuideReminder,
    ModalsService,
    Person,
    PersonApi,
    UserProfileSettingsService,
    Util,
    timeToWaitForReminder
} from '@predictiveindex/client-core';
import { filter } from 'rxjs/operators';
import { InterviewGuideReminderModalComponent } from './InterviewGuideReminderModal.component';

/* I am struggling to keep this simple instead of built out unneeded crap. */

@Injectable({ providedIn: 'root' })
export class InterviewReminderService {
    constructor(
        private userProfile: UserProfileSettingsService,
        private router: Router,
        private modalsService: ModalsService,
        private personApi: PersonApi,
        private context: ContextDataService,
        private util: Util
    ) {
        this.router.events
            .pipe(filter((e) => e instanceof NavigationEnd))
            .subscribe(() => {
                this.checkForRemindersToDisplay();
            });
    }

    start() {
        // this is a hook to make sure this is running
    }

    setReminder(
        personId: string,
        jobId: string,
        companyId = this.userProfile.currentCompany,
        date = new Date()
    ): void {
        this.userProfile.addReminder(
            new InterviewGuideReminder({
                personId,
                companyId,
                jobId,
                date
            })
        );
    }

    private cleanUpOutdatedReminders(outdatedPeople: Person[]): void {
        if (outdatedPeople.length) {
            outdatedPeople
                .map((p) =>
                    this.userProfile.reminders.find((r) => r.personId === p.id)
                )
                .forEach((outdatedReminder) => {
                    outdatedReminder.completed = true;
                });
            this.userProfile.setUserProfileSetting();
        }
    }

    private addPeopleToContext(people: Person[]): void {
        this.context.clearResultSet();
        this.context.addRowSet(people);
    }

    checkForRemindersToDisplay(): void {
        const now = new Date();

        // Exit early if no reminders to display
        if (!this.userProfile.reminders?.length) {
            return;
        }

        // Newest first
        const reminders = this.userProfile.reminders.sort(
            (a, b) => b.date.getTime() - a.date.getTime()
        );
        const interviewReminderLastShown =
            this.userProfile.interviewReminderLastShown;
        const shouldShowInterviewReminder = !this.util.hasValue(
            interviewReminderLastShown
        )
            ? true
            : now.getTime() >
              interviewReminderLastShown.getTime() + timeToWaitForReminder;

        if (shouldShowInterviewReminder && reminders.length) {
            this.personApi
                .getPersonDetails(reminders.map((r) => r.personId))
                .then((unfilteredPeople) => {
                    // Clean up reminders that already have an impression assigned
                    const outdatedPeople = unfilteredPeople.filter(
                        (p) => p.jobFit.personImpression !== Impression.Unset
                    );
                    this.cleanUpOutdatedReminders(outdatedPeople);

                    // Just show 10 at a time
                    const people = unfilteredPeople
                        .filter(
                            (p) =>
                                p.jobFit.personImpression === Impression.Unset
                        )
                        .slice(0, 10);
                    if (people.length) {
                        this.addPeopleToContext(people);
                        this.modalsService.openModal('reminder', {
                            className: 'reminderModal',
                            TRtitle: null,
                            body: {
                                type: InterviewGuideReminderModalComponent,
                                properties: {
                                    people,
                                    reminders: this.userProfile.reminders
                                }
                            }
                        });
                    }
                    this.userProfile.interviewReminderLastShown = now;
                });
        }
    }
}
