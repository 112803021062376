import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {
    Hire2Link,
    InitDataService,
    Inspire2Link,
    ModuleAccessService,
    ModuleName,
    ModuleUserAccessService,
    UserProfileSettingsService,
    design2Home,
    hire2RoutePath
} from '@predictiveindex/client-core';
import { PermissionsModuleAccessLevel } from '@predictiveindex/client-permissions-lib';

@Injectable({ providedIn: 'root' })
export class HireVersionGuard  {
    private static hireToHire2Map;
    private static hire2ToHireMap;

    constructor(
        private router: Router,
        private userProfileSettingService: UserProfileSettingsService,
        private moduleAccess: ModuleAccessService
    ) {
        HireVersionGuard.hireToHire2Map =
            HireVersionGuard.hireToHire2Map ||
            Object.freeze({
                '/job/directory': hire2RoutePath
            });
        HireVersionGuard.hire2ToHireMap =
            HireVersionGuard.hire2ToHireMap ||
            this.keyValueReverse(HireVersionGuard.hireToHire2Map);
    }

    private keyValueReverse(obj: any) {
        var ret = {};
        for (var key in obj) {
            ret[obj[key]] = key;
        }
        return ret;
    }

    canActivate(
        _routeSnapshot: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const route = state.url;
        // If they dont have access to hire or hirehero self serve they get denied
        if (
            !this.moduleAccess.hasHire &&
            !this.moduleAccess.isHireHeroSelfServe
        ) {
            return false;
        }

        // If they are opted into the beta or its self serve we send to HireHero otherwise they go to hire classic
        if (
            this.moduleAccess.hasHireHero &&
            this.moduleAccess.isHireHeroSelfServe
        ) {
            return this.lookupHire2Route(route, _routeSnapshot.params['id']);
        } else {
            return this.lookupHireRoute(route);
        }
    }

    private lookupRoute(route, routeMap, id?: string) {
        if (id) {
            this.router.navigate([hire2RoutePath, id]);
            return false;
        }

        if (routeMap[route]) {
            this.router.navigate([routeMap[route]]);
            return false;
        }
        return true;
    }

    lookupHire2Route(route: string, id?: string) {
        return this.lookupRoute(route, HireVersionGuard.hireToHire2Map, id);
    }
    lookupHireRoute(route: string, id?: string) {
        return this.lookupRoute(route, HireVersionGuard.hire2ToHireMap, id);
    }
}

@Injectable({ providedIn: 'root' })
export class ModuleSSRedirectorGuard  {
    allowedHireHeroRoutes = ['hire', 'myprofile'];

    constructor(
        private router: Router,
        private moduleAccess: ModuleAccessService,
        private moduleUserAccessService: ModuleUserAccessService,
        private initData: InitDataService
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        if (
            this.moduleAccess.hasHireHero &&
            this.moduleAccess.isHireHeroSelfServe &&
            !this.allowedHireHeroRoutes.includes(
                route.routeConfig.path.toLowerCase()
            ) &&
            this.checkHirePermissions()
        ) {
            this.router.navigate([Hire2Link]);
            return false;
        }

        if (this.moduleAccess.hasDesign2 && this.checkDesignPermissions()) {
            this.router.navigate([design2Home]);
            return false;
        }
        if (this.moduleAccess.hasInspire2 && this.checkInspirePermissions()) {
            this.router.navigate([Inspire2Link]);
            return false;
        }
        return true;
    }

    checkHirePermissions() {
        return (
            this.initData.isSuperAdmin() ||
            this.moduleUserAccessService.hasModuleAccessLevelOrAbove(
                ModuleName.Hire,
                PermissionsModuleAccessLevel.LimitedAccess
            )
        );
    }

    checkDesignPermissions() {
        return (
            this.initData.isSuperAdmin() ||
            this.moduleUserAccessService.hasModuleAccessLevelOrAbove(
                ModuleName.Design,
                PermissionsModuleAccessLevel.LimitedAccess
            )
        );
    }

    checkInspirePermissions() {
        return (
            this.initData.isSuperAdmin ||
            this.moduleUserAccessService.hasModuleAccessLevelOrAbove(
                ModuleName.Inspire,
                PermissionsModuleAccessLevel.LimitedAccess
            )
        );
    }
}
