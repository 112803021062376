import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AppLoadHelperService {
    private _isComplete = false;

    constructor() {}

    set isComplete(status: boolean) {
        this._isComplete = status;
    }

    get isComplete() {
        return this._isComplete;
    }
}
