import { Injectable } from '@angular/core';
import { ApiService, PI } from '@predictiveindex/client-core';

@Injectable({
    providedIn: 'root'
})
export class ShareLinkLoadingApi {
    private apiVersion = 'v5';

    constructor(private api: ApiService) {}

    async createPermissions(
        companyId: string,
        linkId: string
    ): Promise<string> {
        var url = companyId
            ? `/api/${this.apiVersion}/companies/${companyId}/sharelink/${linkId}`
            : `/api/${this.apiVersion}/sharelink/${linkId}`;

        const [server] = await this.api.post<any>(
            url,
            null,
            PI.appSettings.designApiUrl
        );
        return server;
    }
}
