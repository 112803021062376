<div class="upgrade-failure-banner-container">
    <div>
        <span class="remaining-days-section"
            >{{ messageContent | translate }}&nbsp;</span
        >
        <span class="upgrade-message">{{ upgradeMessageKey | translate }}</span>
    </div>
    <div>
        <button type="button" class="close" (click)="closeBanner()">
            <abstract-icon [iconType]="IconType.Close"> </abstract-icon>
        </button>
    </div>
</div>
