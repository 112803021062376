import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreUiModule } from '@predictiveindex/client-core';
import { FavoritePassToggleComponent } from './FavoritePassToggle.component';

@NgModule({
    imports: [CommonModule, CoreUiModule],
    declarations: [FavoritePassToggleComponent],
    exports: [FavoritePassToggleComponent],
    providers: []
})
export class FavoritePassToggleModule {}
