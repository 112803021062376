import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {
    DevelopmentPorts,
    FeatureFlags,
    FeatureFlagsService
} from '@predictiveindex/client-core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminProxyGuard  {
    isGlobalAdminEnabled: boolean = false;

    constructor(
        private router: Router,
        private featureFlags: FeatureFlagsService
    ) {
        this.isGlobalAdminEnabled = this.featureFlags.getByFlag(
            FeatureFlags.enableGlobalAdmin
        );
    }

    canActivate(
        _next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        if (this.isGlobalAdminEnabled) {
            let port = null;
            // Check if the app is in production or development build
            if (window.location.hostname.includes('localhost')) {
                // In development use the local admin port instead of the standard 443/80
                port = DevelopmentPorts.admin;
            }
            /*
                    This updates the URl depending on if we are in development or not.  In development we use the local admin server at
                    localhost:4216.  In production mode we use the normal URL such as node-dev.predictiveindex.com.
    
                    In the deployed environment, Azure Front Door (AFD) will handle the path routing.
                */
            window.location.assign(
                `${window.location.protocol}//${
                    // locally use the hostname otherwise preserve the hostname + port
                    port ? window.location.hostname : window.location.host
                    // locally use the provided port otherwise do not change the port
                }${port ? ':' + port : ''}${state.url}`
            );
        } else {
            this.router.navigate(['administration']);
        }
        return false;
    }
}
