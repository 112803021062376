import { Injectable, NgZone } from '@angular/core';
import {
    DataCollectionService,
    EventControlUtility
} from '@predictiveindex/client-core';

@Injectable({
    providedIn: 'root'
})
export class SpaDataCollectionService {
    constructor(
        private dataCollection: DataCollectionService,
        private ngZone: NgZone
    ) {}

    public initializeDataCollection(initData: any) {
        this.ngZone.runOutsideAngular(() => {
            this.dataCollection.initializeDataCollection(initData);
        });
    }

    public stopPropagationAndCollectData(event: Event, preventDefault = false) {
        EventControlUtility.stopPropagationAndCollectData(
            event,
            true, // SPA data collection is enabled for all users
            preventDefault
        );
    }
}
