import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    FeatureFlags,
    FeatureFlagsService,
    ModuleUserAccessService
} from '@predictiveindex/client-core';
import { PermissionsModuleAccessLevel } from '@predictiveindex/client-permissions-lib';

@Injectable({ providedIn: 'root' })
export class HasSubscriptionManager  {
    subscriptionManagerEnabled: boolean = false;
    subscriptionManagerAdminOnlyPaths = [
        'billing',
        'subscriptionplans',
        'plandetails'
    ];

    constructor(
        private router: Router,
        private featureFlags: FeatureFlagsService,
        private moduleUserAccess: ModuleUserAccessService
    ) {
        this.subscriptionManagerEnabled = this.featureFlags.getByFlag(
            FeatureFlags.allowAccessToSubscriptionManagerRoutes
        );
    }

    canActivate() {
        if (
            this.subscriptionManagerEnabled &&
            this.moduleUserAccess.hasAccessLevelForAnyModule(
                PermissionsModuleAccessLevel.AllModuleAdmin
            )
        ) {
            return true;
        }
        this.router.navigate(['dashboard']);
        return false;
    }
}
