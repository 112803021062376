import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { PI } from '@predictiveindex/client-core';

if (environment.production || PI.appSettings.enableProdMode) {
    enableProdMode();
    const statusPageEmbedScript = window.document.createElement('script');
    statusPageEmbedScript.src =
        'https://y0l4g43nfndz.statuspage.io/embed/script.js';
    window.document.body.appendChild(statusPageEmbedScript);
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
