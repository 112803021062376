import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {
    FeatureFlags,
    FeatureFlagsService,
    InitDataService,
    ModuleAccessService,
    RoleType,
    UserProfileSettingsService
} from '@predictiveindex/client-core';

@Injectable({ providedIn: 'root' })
export class ExplainerPageGuard {
    explainerPagesEnabled: boolean = false;

    constructor(
        private router: Router,
        private featureFlags: FeatureFlagsService,
        private moduleAccess: ModuleAccessService,
        private userProfileSettingsService: UserProfileSettingsService,
        private init: InitDataService
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        const module: string = route.paramMap.get('module');
        let canActivate =
            // Allow 1.0 users to access Perform internal activations page only
            (!this.isLegacyOnlyAccount() || module === 'perform') &&
            !this.isThirdPartyUser() &&
            this.allowRouteNavigation(module);
        if (!canActivate) {
            this.router.navigate(['dashboard']);
        }
        return canActivate;
    }

    private isThirdPartyUser() {
        return !this.userProfileSettingsService.isUserLoggedInDefaultCompany();
    }

    private isLegacyOnlyAccount() {
        return (
            (this.moduleAccess.hasDesign1 ||
                this.moduleAccess.hasHire ||
                this.moduleAccess.hasInspire1 ||
                this.moduleAccess.hasDiagnose1) &&
            !this.moduleAccess.hasV2ModuleAccess
        );
    }

    private allowRouteNavigation(module: string): boolean {
        let allowNavigation: boolean = false;
        switch (module) {
            case 'design':
                allowNavigation = this.featureFlags.getByFlag(
                    FeatureFlags.enableDesignExplainerPages
                );
                break;
            case 'hire':
                allowNavigation = this.featureFlags.getByFlag(
                    FeatureFlags.enableHireExplainerPages
                );
                break;
            case 'diagnose':
                allowNavigation = this.featureFlags.getByFlag(
                    FeatureFlags.enableDiagnoseExplainerPages
                );
                break;
            case 'perform':
                allowNavigation =
                    !this.moduleAccess.hasPerform &&
                    this.featureFlags.getByFlag(
                        FeatureFlags.enablePerformInternalActivation
                    ) &&
                    this.init.user.hasPermissionLevelOrGreater(
                        RoleType.AccountAdmin
                    );
                break;
            default:
                break;
        }
        return allowNavigation;
    }
}
