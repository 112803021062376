import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {
    FeatureFlags,
    FeatureFlagsService,
    InitDataService,
    PI2UpgradeStatus
} from '@predictiveindex/client-core';

@Injectable({
    providedIn: 'root'
})
export class PI2UpgradeGuard {
    constructor(
        private router: Router,
        private initDataService: InitDataService,
        private featureFlagService: FeatureFlagsService
    ) {}

    async canActivate(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        await this.featureFlagService.completionPromise;

        if (
            this.featureFlagService.getByFlag(
                FeatureFlags.enableUpgradeInProgressPage
            )
        ) {
            if (state.url === '/upgrade-in-progress') {
                return true;
            }

            const upgradeStatus = this.parseUpgradeStatus(
                this.initDataService.account.pi2UpgradeStatus
            );
            if (
                upgradeStatus === PI2UpgradeStatus.UpgradeInProgress ||
                upgradeStatus === PI2UpgradeStatus.UpgradeFailure
            ) {
                return this.router.createUrlTree(['/upgrade-in-progress']);
            }
        }

        return true;
    }

    async canActivateChild(
        _: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        return await this.canActivate(_, state);
    }

    private parseUpgradeStatus(upgradeStatus: string): PI2UpgradeStatus {
        return Object.values(PI2UpgradeStatus).includes(
            upgradeStatus as PI2UpgradeStatus
        )
            ? (upgradeStatus as PI2UpgradeStatus)
            : PI2UpgradeStatus.Unknown;
    }
}
