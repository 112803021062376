import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconType, TranslationService } from '@predictiveindex/client-core';

@Component({
    selector: 'upgrade-failure-banner',
    templateUrl: './upgrade-failure-banner.component.html',
    styleUrls: ['./upgrade-failure-banner.component.less']
})
export class UpgradeFailureBannerComponent {
    IconType = IconType;
    messageContent: string = 'pi2-upgrade-failure.banner.bold.text';
    upgradeMessageKey: string = 'pi2-upgrade-failure.banner.regular.text';

    @Input()
    showBanner = false;
    @Output()
    showBannerChange = new EventEmitter<boolean>();

    constructor(TR: TranslationService) {}

    closeBanner() {
        this.showBanner = false;
        this.showBannerChange.emit(this.showBanner);
    }
}
