import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {
    InitDataService,
    ModuleAccessService,
    ModuleName,
    ModuleUserAccessService
} from '@predictiveindex/client-core';
import { PermissionsModuleAccessLevel } from '@predictiveindex/client-permissions-lib';

@Injectable({
    providedIn: 'root'
})
export class DiagnoseProxyGuard  {
    constructor(
        private router: Router,
        private moduleAccess: ModuleAccessService,
        private moduleUserAccess: ModuleUserAccessService,
        private initDataService: InitDataService
    ) {}

    /**
     * A user should get redirected away from Survey Center when:
     * IsDiagnose = false
     * OR
     * IsDiagnoseSelfServe == false
     */
    canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.moduleAccess.hasDiagnose) {
            if (this.moduleAccess.isDiagnoseSelfServe) {
                return this.canActivateDiagnose2(state);
            }
            this.router.navigate(['engage/employeeExperience']);
            return false;
        } else {
            this.router.navigate(['dashboard']);
            return false;
        }
    }

    canActivateDiagnose2(state: RouterStateSnapshot) {
        this.checkIfUserHasAccessLevelMap();
        if (
            // The ModuleName must be `Diagnose` here as of 11/22/2022 because
            // that is how it is defined in the `UserAccessLevelMap` even though
            // it means `Diagnose2`.
            this.moduleUserAccess.hasModuleAccessLevelOrAbove(
                ModuleName.Diagnose,
                PermissionsModuleAccessLevel.LimitedAccess
            )
        ) {
            window.location.assign(
                window.location.protocol +
                    '//' +
                    window.location.host +
                    state.url
            );
            return true;
        }
        this.router.navigate(['dashboard']);
        return false;
    }

    /**
     * Checks to see if the current user has any values in their access
     * level map.
     *
     * This just logs if they didn't have it, so it can be looked into later.
     * This might indicate the user wasn't setup in permissions correctly.
     *
     * If these errors never crop up, then this method should be deleted.
     *
     * Created 11/22/2022.
     */
    private checkIfUserHasAccessLevelMap() {
        if (
            !this.initDataService.userAccessLevelMap ||
            this.initDataService.userAccessLevelMap.size === 0
        ) {
            console.error(
                'User did not have a userAccessLevelMap or it was ' +
                    'empty which may ' +
                    'indicate that they arent setup in permissions correctly. ' +
                    'Access is being denied to Diagnose 2.0.'
            );
        }
    }
}
